<template>
    <div class="list-info">
        <top-bar :title="'服务经费'" :left="true"></top-bar>
        <div class="filterBar">
            <van-row>
                <van-col span="12">
                    <p @click="yearDateShow = !yearDateShow" :style="{color:yearDateShow?'#387FF5':'#666666'}">{{selectDate.year}}
                        <img :src="require(`@/assets/img/${yearDateShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
                    </p>
                </van-col>
                <van-col span="12">
                    <p @click="monthDateShow = !monthDateShow" :style="{color:monthDateShow?'#387FF5':'#666666'}">{{selectDate.month}}
                        <img :src="require(`@/assets/img/${monthDateShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
                    </p>
                </van-col>
            </van-row>
        </div>
        <van-popup v-model="yearDateShow" position="bottom"  >
            <van-datetime-picker :columns-order="['month', 'year' , 'day']" @confirm="yearDateConfim" @cancel="yearDateShow = false" :formatter="formatDate"
                                 type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2030, 10, 1)"/>
        </van-popup>
        <van-popup v-model="monthDateShow" position="bottom"  >
            <van-datetime-picker cancel-button-text="重置" :columns-order="['year' , 'month', 'day']" @confirm="monthDateConfim" @cancel="reset" :formatter="formatterMonth"
                                 type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
        </van-popup>
        <div class="addBtn" @click="goAdd">
            <img src="@/assets/img/add.png" alt="">
        </div>
        <div class="cont">
            <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
                    <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
                    <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
                        <div class="content">
                            <div class="content-text">
                                <div class="top-text">{{item.year + '年' + item.month + '月'}}</div>
                                <div class="bottom-text">{{'总人数：'+item.num}}<span style="float:right">{{'总金额：'+item.amount}}</span></div>
                            </div>
                        </div>
                    </div>
                </van-list>
                <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
            <!-- </van-pull-refresh> -->
        </div>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterYear} from '@/utils/utils'
import {formatterOnlyMonth} from '@/utils/utils'
import { mapMutations } from 'vuex'
export default {
    name: 'ffsms',
    components :{
        topBar
    },
    data() {
        return {
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2025, 10, 1),
            currentDate: new Date(),
            subName: '所属小区',
            loading: false,
            finished: false,
            yearDateShow: false,
            monthDateShow: false,
            dateBarShow: false,
            subShow: false,
            page: 0,
            limit: 10,
            searchValue: '',
            selectDate: {
                year: new Date().getFullYear(),
                month: '月份',
            },
            pullLoading:false,
            subarea: '',
            subList: [],
            totalCount: 0,
            dataList: []
        };
    },
    methods: {
        ...mapMutations,
        getInfo (id) {
            this.$router.push({path: '/ffsms-add', query: {id: id}})
        },
        getDataList () {
            this.page++
            this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
            this.$http({
                url: this.$http.adornUrl('/wxapp/ffsms/list'),
                method: 'post',
                params: this.$http.adornParams({
                    // orgId: this.$orgId,
                    page: this.page,
                    limit: this.limit,
                    year : this.selectDate.year,
                    month: this.selectDate.month ==='月份' ? '': this.selectDate.month
                })
            }).then(({data})=> {
                this.pullLoading =false
                if (data.code === 0) {
                    this.totalCount = data.page.totalCount
                    this.dataList = this.dataList.concat(data.page.list)
                    if (this.dataList.length == data.page.totalCount) {
                        this.finished = true
                    }

                    // 加载状态结束
                    this.loading = false;
                    this.$toast.clear()
                }
                this.$toast.clear()
            },err=> {this.$toast.clear()})
        },
        getSubList () {
            this.$http({
                url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
                method: 'post',
                params: this.$http.adornParams({
                    communityId: this.$orgId
                })
            }).then(({data})=> {
                if (data.code === 0) {
                    this.subList = data.subAreaList
                } else {
                    this.$toast.fail(data.msg);
                }
            })
        },
        /**
         * 页面相关事件处理函数--用户下拉动作
         */
        onPullDownRefresh(e) {
            this.finished = false
            this.dataList = []
            this.page = 0;
            this.getDataList()
        },
        formatDate (type, val) {
            if (type === 'year') {
                return val + '年'
            }else {
                return null
            }
        },
        formatterMonth(type, val) {
            if (type === 'month') {
                return val + '月';
            }else{
                return null
            }
        },
        onCancel() {
            this.page = 0
            this.dataList = []
            this.finished = false
            this.searchValue = ''
            this.getDataList()
        },
        monthDateConfim (value) {
            this.finished = false
            let d = new Date(this.selectDate.month)
            this.selectDate.month = formatterOnlyMonth(value)
            this.page = 0
            this.dataList = []
            this.getDataList()
            this.monthDateShow = false
        },
        reset(){
            this.finished = false
            this.monthDateShow = false
            this.selectDate.month = '月份';
            this.page = 0
            this.dataList = []
            this.getDataList()
        },
        yearDateConfim (value) {
            this.finished = false
            let d = new Date(this.selectDate.year)
            this.selectDate.year = formatterYear(value)
            this.yearDateShow = false
            this.page = 0
            this.dataList = []
            this.getDataList()
        },
        changeDate () {
            this.dataList = []
            this.page = 0
            this.finished = false
            this.getDataList()
        },
        dateClose () {
            this.selectDate = {
                endTime: '',
                startTime: ''
            }
        },
        gridConfirm (value,index) {
            this.page = 0
            this.dataList = []
            this.subName = value.name
            this.subarea = value.id
            this.finished = false
            this.getDataList()
            this.subShow = false
        },
        goAdd () {
            this.$router.push({path: '/ffsms-add', meta:{keepAlive: true}})
        },
        formatter(type, val) {
            if (type === 'month') {
                return `${val}月`;
            } else if (type === 'day') {
                return `${val}日`;
            }
            return val;
        }
    },
    mounted () {
        console.log(1)
        this.$toast.loading({
            duration: 0,
            message: '加载中...',
            forbidClick: true,
        });
        this.userId = this.$globalData.userInfo.userId
        this.getDataList()
        this.getSubList()
    }
}
</script>
